import React from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { logout } from '../actions/auth'
import { Menu, Icon } from 'antd'

const Nav = ({current, logout, token}) => {
  return (
    <div>
      <Menu selectedKeys={[current]} mode="horizontal">
        <Menu.Item key="home">
          <Link to={`/`}>
            <Icon type="home" />
            Home
          </Link>
        </Menu.Item>
        <Menu.Item key="cities">
        <Link to="/cities">
          <Icon type="bank" />
          Cities
        </Link>
        </Menu.Item>

        <Menu.Item key="tags">
        <Link to="/tags">
          <Icon type="tag" />
          Tags
        </Link>
      </Menu.Item>
        
        <Menu.Item key="profile">
          <Link to="/places">
            <Icon type="profile" />
            Places
          </Link>
        </Menu.Item>
       
        <Menu.Item key="tours">
          <Link to="/tours">
            <Icon type="pushpin" />
            Tours
          </Link>
        </Menu.Item>

        <Menu.Item key="hiking">
          <Link to="/hiking">
            <Icon type="compass" />
            Hiking
          </Link>
        </Menu.Item>
       

        <Menu.Item key="deals">
          <Link to="/deals">
            <Icon type="calculator" />
            Deals
          </Link>
        </Menu.Item>

        <Menu.Item key="mice">
          <Link to="/mice">
            <Icon type="shopping" />
            Mice
          </Link>
        </Menu.Item>
      

      <Menu.Item key="stories">
        <Link to={`/stories`}>
          <Icon type="heart" />
          Stories
        </Link>
        </Menu.Item>

      

     
        
       
        <Menu.Item key="docs">
          <a href='https://places.api.upoui.com/api-docs/' rel="noopener noreferrer" target='_blank'>
          <Icon type="wallet" />
            API Docs
          </a>
        </Menu.Item>

        {token && (
          <Menu.Item style={{ float: "right" }} key="logout">
            <div
              onClick={() => {
                logout();
              }}
            >
              <Icon type="logout" />
              Logout
            </div>
          </Menu.Item>
        )}
      </Menu>
    </div>
  );
}


const mapStateToProps = state => ({
  ...state.Auth
})
 
const mapDispatchToProps = dispatch => ({
logout: () => dispatch(logout())
})

export default connect(mapStateToProps, mapDispatchToProps)(Nav);